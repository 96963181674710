/*!
*
* Template: SocialV - Responsive Bootstrap 5 Admin Dashboard Template
* Author: iqonic.design
* Design and Developed by: iqonic.design
* NOTE: This file contains the styling for responsive Template.
*
*/

@import "./custom/helper/fonts";

// Configuration
@import "./bootstrap/functions";
@import "./custom/variables";
@import "./custom/variables/index";
@import "./bootstrap/variables";
@import "./bootstrap/maps";
@import "./bootstrap/mixins";
@import "./bootstrap/utilities";

// Custom Mixin And Helper
@import "./custom/helper/functions";
@import "./custom/helper/mixins";

// Layout & components
@import "./custom/helper/root";
@import "./bootstrap/root";
@import "./bootstrap/reboot";
@import "./bootstrap/type";
@import "./bootstrap/images";
@import "./bootstrap/containers";
@import "./bootstrap/grid";
@import "./bootstrap/tables";
@import "./bootstrap/forms";
@import "./bootstrap/buttons";
@import "./bootstrap/transitions";
@import "./bootstrap/dropdown";
@import "./bootstrap/button-group";
@import "./bootstrap/nav";
@import "./bootstrap/navbar";
@import "./bootstrap/card";
@import "./bootstrap/accordion";
@import "./bootstrap/breadcrumb";
@import "./bootstrap/pagination";
@import "./bootstrap/badge";
@import "./bootstrap/alert";
@import "./bootstrap/progress";
@import "./bootstrap/list-group";
@import "./bootstrap/close";
@import "./bootstrap/toasts";
@import "./bootstrap/modal";
@import "./bootstrap/tooltip";
@import "./bootstrap/popover";
@import "./bootstrap/carousel";
@import "./bootstrap/spinners";
@import "./bootstrap/offcanvas";

// Helpers
@import "./bootstrap/helpers";

// Utilities
@import "./bootstrap/utilities/api";

// // Extra Components and Utilities and plugins
@import "./custom/variables/icon-size";
@import "./custom/components/components";
@import "./custom/helper/utilities";
@import "./custom/plugins/plugins";

@import "./custom/mode/dark/index";
@import "./custom/mode/rtl/index";


/* fallback */
@font-face {
    font-family: 'Material Symbols Outlined';
    font-style: normal;
    font-weight: 100 700;
    src: url(https://fonts.gstatic.com/s/materialsymbolsoutlined/v75/kJEhBvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oFsI.woff2) format('woff2');
  }


.material-symbols-outlined {
    font-family: 'Material Symbols Outlined';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}
  
.modal-backdrop {
  --bs-backdrop-opacity: 0.05;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.search-container {
  position: fixed;
  left: 40px;
  right: 40px;
  top: 100px;
  bottom: 0;
}

.search-container .col {
  overflow-y: scroll;
  height: 100%;
}

@media (max-width:768px) {
  .search-container .col {
    height: 50%;
  }
}

.chat-container {
  .rcw-full-screen {
    width: 600px;
    height: 85vh;
    max-height: 960px;
    margin: auto;
    padding-top: 20px;
    position: relative;
  }

  .rcw-header {
    height: 50px;
  }

  @media (max-width: 768px) {
    .rcw-full-screen {
      padding-top: 5px;
      height: 75vh;
    }
    .rcw-conversation-container {
      box-shadow: none;
    }
    .rcw-header {
      position: fixed;
      width: 100%;
      opacity: 0.4;
    }
  }
}

.chat-container .col {
}

.grecaptcha-badge { 
  visibility: hidden;
}
