.pro-button {
  display: block;
  text-align: center;
  text-decoration: none;
  font-weight: 800;
  font-size: 1em;
  text-transform: uppercase;
  color: white;

  border-radius: 10px;
  margin: 10px;
  padding: 1em 3em;
  background-size: 200% auto;
  color:white;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
  background-image: linear-gradient(to right, #895cf2 0%, #ffabf4 50%, #895cf2 100%);
  transition: 0.5s;

  &:hover {
    background-position: right center;
  }
}

.menu-button {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 800;
  font-size: 0.7em;
  text-transform: uppercase;
  color: black;
  height: 3em;
  border-radius: 10px;
  margin: 3px;
  padding: 1em 1em;
  background-size: 200% auto;
  color:white;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
  background-image: linear-gradient(to right, #1d8b77 0%, #64bfc4 50%, #5ccff2 100%);
  transition: 0.5s;

  &:hover {
    background-position: right center;
  }
}

.pro-small-button {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 800;
  font-size: 0.7em;
  text-transform: uppercase;
  color: black;
  height: 3em;
  border-radius: 10px;
  margin: 3px;
  padding: 1em 1em;
  background-size: 200% auto;
  color:white;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
  background-image: linear-gradient(to right, #b50606 0%, #c48810 60%, #d83d42 100%);
  transition: 0.5s;

  &:hover {
    background-position: right center;
  }
}

